const resizeFontsize = () => {
  (function (win, doc) {
    const change = () => {
      let dom = doc.documentElement || doc.body;

      if (dom.clientWidth > 800) {
        dom.style.fontSize = dom.clientWidth / 10 + 'px';
      } else {
        dom.style.fontSize = 192 * dom.clientWidth / 375 + 'px';
      }
    };

    change();

    win.addEventListener('resize',
      change, false);
  })(window, document);
};

export default resizeFontsize;
