import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import {
  Button,
  Form,
  FormItem,
  Row,
  Col,
  Icon,
  Drawer,
  Tabs,
  TabPane,
  Input,
  Option,
  Checkbox,
  Collapse,
  CollapseItem,
  Select,
  MessageBox,
  Menu,
  Submenu,
  MenuItem,
  RadioGroup,
  RadioButton,
  Carousel,
  CarouselItem,
  Image,
} from 'element-ui';
import '@/assets/iconfont/iconfont.css';

// 移动端复制
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';

import resizeFontsize from './utils/rem';
resizeFontsize();

Vue.use(Button);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Row);
Vue.use(Col);
Vue.use(Icon);
Vue.use(Drawer);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Input);
Vue.use(Select);
Vue.use(Option);
Vue.use(Checkbox);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(RadioGroup);
Vue.use(RadioButton);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Image);
Vue.component(CollapseTransition.name, CollapseTransition);

Vue.config.productionTip = false;
Vue.prototype.$totast = MessageBox;

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
